export const authTokenNeeded = [
  'memberLogin',
  'addWorkspace',
  'workspaces',
  'profile',
  'changeProfileNames',
  'changePassword',
  'changeUserProfilePicture',
  'changeDefaultLanguage',
  'changeDefaultTimezone',
  'changeEmail',
  'deviceRegister',
  'updateOnlineStatus',
  'setUserDND',
  'workspaceSubscription',
  'updateBusiness',
  'createPaymentIntent',
  'revoke', // logout mutation
];
export const tokenNotNeeded = ['login', 'register'];
export const refreshTokenNeeded = ['refreshToken'];
export const refreshAllTokenQuery = `
      mutation refreshAllToken($data: RefreshTokenInputData!)  {
        refreshAllToken(data: $data) {
          status
          error {
            code
            message
            errorKey
          }
          data {
            accessToken
            authToken
            refreshToken
          }
        }
      }
`;

export enum Tokens {
  accessToken = 'accessToken',
  authToken = 'authToken',
  refreshToken = 'refreshToken',
}

export const salesDialerSub = ['updateCreditSalesDialer'];
