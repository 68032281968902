import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { ToastMessage } from 'components/atoms';
import { LOGOUT } from 'graphql/user';

interface IProps {
  callback?: () => void;
}

export const useLogoutMutation = ({ callback }: IProps) => {
  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: async response => {
      const { error } = response.revoke;
      if (error === null) {
        callback?.();
        return;
      }
      datadogLogs.logger.error('Logout Error', {
        datadogError: { ...error, errorMessage: error?.message },
        context: 'logout',
      });

      ToastMessage({
        content: 'Something went wrong',
        type: 'danger',
      });
    },
    onError: (error: any) => {
      datadogLogs.logger.error('Logout Error', {
        datadogError: { ...error, errorMessage: error?.message },
        context: 'logout',
      });
      ToastMessage({
        content: 'Something went wrong',
        type: 'danger',
      });
    },
  });

  const logout = async () => {
    await logoutMutation({
      variables: {
        data: {
          platform: 'WEB',
        },
      },
    });
  };
  return {
    logout,
  };
};
